/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// const {
//   config
// } = require("./src/libs/cq_config")
// const galite = require('ga-lite')

// const {
//   // ready,
//   readyCQ
// } = require('./src/libs/utils');

require("./src/styles/style.css")
const {
  analytics
} = require("./src/libs/rudderanalytics")
// const {
//   rudderanalytics
// } = require("./src/libs/rudderanalytics")

exports.onRouteUpdate = function ({
  location
}) {
  // ga-lite
  // galite('send', 'pageview')
  // END ga-lite
  // RudderStack
  // const rudderanalytics = window.rudderanalytics
  // // rudderanalytics.track("Test track", {
  // //   testValue: 16,
  // //   location: location.href,
  // // })
  // if (
  //   // process.env.NODE_ENV === `production` &&
  //   typeof rudderanalytics === `object`
  // ) {
  //   rudderanalytics.page()
  // }
  // console.log("Route update")
  if (typeof rudderanalytics === `object`) {
    analytics.page()
    if (window.ga) {
      window.ga(function (tracker) {
        var clientId = tracker.get('clientId');
        analytics.identify(undefined, {
          gaClientId: clientId
        })
        // console.log('My GA universal client ID is: ' + clientId);
      });
    }
  }
  // End RudderStack

  // Carrotquest
  // readyCQ(() => {
  //   const carrotquest = window.carrotquest;
  //   if (process.env.NODE_ENV === `production` && typeof carrotquest === `object`) {
  //     carrotquest.track('pageView', location);
  //     let cpc;
  //     if (location.href.search('&gclid=') > -1) {
  //       cpc = 'google';
  //     }
  //     const props = {
  //       lastPageView: location.href,
  //     }
  //     if (cpc) {
  //       props.cpc = cpc;
  //     }
  //     carrotquest.identify(props);
  //   }
  // });
  // window.dispatchEvent(new Event("gatsby-route-change"))
  // const carrotquest = window.carrotquest
  // if (
  //   process.env.NODE_ENV === `production` &&
  //   typeof carrotquest === `object`
  // ) {
  //   carrotquest.connect("39510-243550d73178a0b6ea092f424b", config)
  //   carrotquest.track("pageView", location)
  //   let cpc
  //   if (location.href.search("&gclid=") > -1) {
  //     cpc = "google"
  //   }
  //   const props = {
  //     lastPageView: location.href,
  //   }
  //   if (cpc) {
  //     props.cpc = cpc
  //   }
  //   carrotquest.identify(props)
  // }
  // End Carrotquest
}