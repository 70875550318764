// function ready(fn) {
//   if (document.readyState != 'loading') {
//     fn();
//   } else if (document.addEventListener) {
//     document.addEventListener('DOMContentLoaded', fn);
//   } else {
//     document.attachEvent('onreadystatechange', function () {
//       if (document.readyState != 'loading')
//         fn();
//     });
//   }
// }

declare global {
  // interface Window {
  //   carrotquest: any
  // }
}

// function readyCQ(fn) {
//   // console.log("Checking CQ loaded...", window.carrotquest);
//   if (window.carrotquest) {
//     // window.carrotquest.connect("39510-243550d73178a0b6ea092f424b");
//     return fn()
//   } else {
//     setTimeout(() => readyCQ(fn), 500)
//   }
// }

// function trackCQ(event, props) {
//   const carrotquest = window.carrotquest
//   if (carrotquest) {
//     carrotquest.track(event, props)
//   }
// }

// function identifyCQ(props) {
//   const carrotquest = window.carrotquest
//   if (carrotquest) {
//     carrotquest.identify(props)
//   }
// }

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ")
}

const removeLangPrefix = (pathname: string, locale: string) => {
  if (pathname.search(`/${locale}`) === 0) {
    return pathname.slice(locale.length + 1)
  }
  return pathname
}

const isSSR = typeof window === "undefined"

const removeEmptyKeys = (object: any): any => {
  const keys = Object.keys(object)
  const newObject = {}
  for (const key of keys) {
    // if (object[key] !== null && object[key] !== undefined) {
    if (object[key]) {
      newObject[key] = object[key]
    }
  }
  return newObject
}

export {
  // ready,
  // readyCQ,
  // trackCQ,
  // identifyCQ,
  classNames,
  removeLangPrefix,
  isSSR,
  removeEmptyKeys,
}
