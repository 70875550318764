exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cardpay-tsx": () => import("./../../../src/pages/cardpay.tsx" /* webpackChunkName: "component---src-pages-cardpay-tsx" */),
  "component---src-pages-complete-ok-tsx": () => import("./../../../src/pages/complete_ok.tsx" /* webpackChunkName: "component---src-pages-complete-ok-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-get-docs-tsx": () => import("./../../../src/pages/get_docs.tsx" /* webpackChunkName: "component---src-pages-get-docs-tsx" */),
  "component---src-pages-i-[hash]-tsx": () => import("./../../../src/pages/i/[hash].tsx" /* webpackChunkName: "component---src-pages-i-[hash]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-tsx": () => import("./../../../src/pages/invoice.tsx" /* webpackChunkName: "component---src-pages-invoice-tsx" */),
  "component---src-pages-land-trial-tsx": () => import("./../../../src/pages/land-trial.tsx" /* webpackChunkName: "component---src-pages-land-trial-tsx" */),
  "component---src-pages-nodocs-tsx": () => import("./../../../src/pages/nodocs.tsx" /* webpackChunkName: "component---src-pages-nodocs-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../../../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-refund-tsx": () => import("./../../../src/pages/refund.tsx" /* webpackChunkName: "component---src-pages-refund-tsx" */),
  "component---src-pages-request-tsx": () => import("./../../../src/pages/request.tsx" /* webpackChunkName: "component---src-pages-request-tsx" */),
  "component---src-pages-review-tsx": () => import("./../../../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-send-vchasno-tsx": () => import("./../../../src/pages/send_vchasno.tsx" /* webpackChunkName: "component---src-pages-send-vchasno-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news-post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */)
}

