import { getPartnerId } from "./seo"
import { isSSR } from "./utils"

declare global {
  interface Window {
    rudderanalytics: any
  }
}

export const analytics = {
  track: (event: string, properties?: any, options?: any, callback?: any) => {
    if ("undefined" == typeof window.rudderanalytics) {
      // console.log("Track")
      rudderLoader()
    }
    properties.partnerId = getPartnerId()
    window.rudderanalytics.track(event, properties, options, callback)
  },
  identify: (userId?: string, traits?: any, options?: any, callback?: any) => {
    if ("undefined" == typeof window.rudderanalytics) {
      // console.log("Identify")

      rudderLoader()
    }
    if (userId) {
      window.rudderanalytics.identify(userId, traits, options, callback)
    } else {
      window.rudderanalytics.identify(traits, options, callback)
    }
  },
  page: (
    category?: string,
    name?: string,
    properties?: any,
    options?: any,
    callback?: any
  ) => {
    // console.log("Page")
    if ("undefined" == typeof window.rudderanalytics) {
      rudderLoader()
    }
    if (properties) {
      properties.partnerId = getPartnerId()
    }
    window.rudderanalytics.page(category, name, properties, options, callback)
  },
  getAnonymousId: (): string => {
    if (!isSSR) {
      if ("undefined" == typeof window.rudderanalytics) {
        // console.log("getAnonymousId")

        rudderLoader()
      }
      return window.rudderanalytics.getAnonymousId()
    }
  },
}

export const rudderLoader = function () {
  if ("undefined" == typeof window.rudderanalytics) {
    // console.log("Load RS")
    let rudderanalytics: any = (window.rudderanalytics = [])

    var methods = [
      "load",
      "page",
      "track",
      "identify",
      "alias",
      "group",
      "ready",
      "reset",
      "getAnonymousId",
      "setAnonymousId",
    ]

    for (var i = 0; i < methods.length; i++) {
      var method = methods[i]
      rudderanalytics[method] = (function (methodName) {
        return function () {
          rudderanalytics.push(
            [methodName].concat(Array.prototype.slice.call(arguments))
          )
        }
      })(method)
    }
    var r = document.createElement("script")
    r.type = "text/javascript"
    r.async = true
    r.src = "https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js"
    var a = document.getElementsByTagName("body")[0]
    a.parentNode.insertBefore(r, a)

    window.removeEventListener("scroll", rudderLoader)
    window.removeEventListener("gatsby-route-change", rudderLoader)
    rudderanalytics.load(
      process.env.GATSBY_RUDDER_TOKEN,
      "https://bcbizinfoucez.dataplane.rudderstack.com"
    )
    rudderanalytics.page()
  }
}
