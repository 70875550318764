import { analytics } from "./rudderanalytics"
import { isSSR, removeEmptyKeys } from "./utils"

declare global {
  interface Window {
    gtag: any
  }
}

async function getClientIdFromCookie() {
  if (!isSSR) {
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        resolve("")
      }, 5000) // Таймаут 5 секунд
      window.gtag("get", "G-T54H0X7689", "client_id", (result: string) => {
        clearTimeout(timeout)
        resolve(result)
      })
    })
  }
}

function getClientId() {
  let clientId = getCookie("_ga")
  if (clientId) {
    const splited = clientId.split(".")
    if (splited.length > 2) {
      return splited.slice(2).join(".")
    }
  }
  return null
}

function getPartnerId(): number {
  if (!isSSR) {
    const partnerId = getCookie("partner")
    if (partnerId) {
      return parseInt(partnerId, 10)
    }
  }
}

function getCookie(name: string): string {
  if (!isSSR) {
    const value = document.cookie.replace(
      new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`),
      "$1"
    )
    return value
  }
}

const getAdditionalData = async () =>
  removeEmptyKeys({
    rl_id: analytics.getAnonymousId(),
    clientId: await getClientIdFromCookie(),
    partnerId: getCookie("partner"),
    gclid: getCookie("gclid"),
    utmSource: getCookie("utm_source"),
    utmTerm: getCookie("utm_term"),
  })

export {
  getAdditionalData,
  getClientId,
  getClientIdFromCookie,
  getCookie,
  getPartnerId,
}
